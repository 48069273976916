<template>
  <div class="autocomplete">
    <b-input type="text" :disabled="disable" v-model="search" @input="onChange" :placeholder="placeholder" autocomplete="off" @focus="onFocus" aria-describedby="input-live-help input-live-feedback" :state="validateState"/>
    <b-form-invalid-feedback id="input-live-feedback">
      Enter {{ placeholder }}
    </b-form-invalid-feedback>
    <ul v-show="isOpen" class="autocomplete-results">
      <li v-for="(result, i) in results" :key="i" @click="setResult(result)" class="autocomplete-result">{{ result }}</li>
    </ul>
  </div>
</template>
<script>
  export default {
    name: 'InputAutocomplete',
    props: {
      initval: {
        type: String,
        required: false,
        default: '',
      },
      items: {
        type: Array,
        required: false,
        default: () => [],
      },
      placeholder: {
        type: String,
        required: false,
        default: '',
      },
      disable: {
        type: Boolean,
        required: false,
        default: false,
      },
      mandatory: {
        type: Boolean,
        required: false,
        default: false,
      },
      state: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    data() {
      return {
        search: '',
        results: [],
        isOpen: false,
        validateState: true,
      };
    },
    watch: {
      "$data.isOpen": {
        handler: function (newValue) {
          this.wrapperFix(newValue);
        },
        deep: true,
      },
      "$props.state": {
        handler: function (newValue) {
          this.validateState = newValue;
        },
        deep: true,
      },
    },
    methods: {
      wrapperFix(isOpenCheck) {
        let wrappers = document.getElementsByClassName("el-collapse-item__wrap");
        let cards = document.getElementsByClassName("el-card");
        for (let i = 0; i < wrappers.length; i++) {
          if (isOpenCheck) {
            if (!wrappers[i].classList.contains("fixWrap2")) {
              wrappers[i].classList.add("fixWrap2");
            }
          } else {
            if (wrappers[i].classList.contains("fixWrap2")) {
              wrappers[i].classList.remove("fixWrap2");
            }
          }
        }

        for (let i = 0; i < cards.length; i++) {
          if (isOpenCheck) {
            if (!cards[i].classList.contains("fixWrap2")) {
              cards[i].classList.add("fixWrap2");
            }
          } else {
            if (cards[i].classList.contains("fixWrap2")) {
              cards[i].classList.remove("fixWrap2");
            }
          }
        }
      },
      onFocus() {
        this.isOpen = true;
        this.filterResults();
        if(this.mandatory){
          if(this.search == ''){
            this.validateState = false;
          } else {
            this.validateState = true;
          }
        }
      },
      onChange() {
        this.isOpen = true;
        this.filterResults();
        if(this.mandatory){
          if(this.search == ''){
            this.validateState = false;
          } else {
            this.validateState = true;
          }
        }
      },
      filterResults() {
        this.results = this.items.filter(item => item.toLowerCase().indexOf(this.search.toLowerCase()) > -1);
        if(this.results.length <= 0){
          this.isOpen = false;
        }
      },
      setResult(result) {
        this.search = result;
        if(this.mandatory){
          if(this.search == ''){
            this.validateState = false;
          } else {
            this.validateState = true;
          }
        }
        this.$emit('input', result);
        this.isOpen = false;
      },
      handleClickOutside(evt) {
        if (!this.$el.contains(evt.target)) {
          this.$emit('input', this.search);
          this.isOpen = false;
        }
      },
    },
    mounted() {
      this.search = this.initval;
      document.addEventListener('click', this.handleClickOutside);
    },
    destroyed() {
      document.removeEventListener('click', this.handleClickOutside);
    }
  };
</script>
<style>
  .autocomplete {
    position: relative;
  }

  .autocomplete-results {
    padding: 0;
    margin: 5px 0;
    border: 1px solid #cad1d7;
    border-radius: 4px;
    height: 150px;
    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
    overflow: auto;
    position: absolute;
    background-color: white;
    color: #052b24;
    width: 100%;
    z-index: 100;
    font-size: 1rem;
  }

  .autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
  }

  .autocomplete-result:hover {
    background-color: #4AAE9B;
    color: white;
  }
  .fixWrap2 {
    display: table;
    content: "";
    clear: both;
    width: 100%;
    overflow-y: visible;
  }
  .was-validated .form-control:valid, .form-control.is-valid, .was-validated .custom-select:valid, .custom-select.is-valid {
    border-color: #cad1d7;
  }
</style>